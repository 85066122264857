<template>
  <Header
    :headerText="headerText"
    :goBack="goBack" />
  <DefaultContainer
    :col1="scanning ? 'col-5' : 'col-6'"
    :col2="scanning ? 'col-7' : 'col-6'">
    <template #col1>
      <preview
        v-if="scanning"
        showHintImage
        :image="imageData"/>
      <Hand
        v-if="selectedHand !== null & !scanning"
        :selectedHand="selectedHand"
        :selectedFinger="selectedFinger"
        :isNewPatient="isNewPatient"
        :savedFingers="savedFingers" />
    </template>
    <template #col2>
      <discard-fingerprint
        v-if="showDiscardSection"
        :hand="selectedHand"
        :finger="selectedFinger"/>
      <multi-selection
        v-else-if="!scanning"
        :onSelectHand="onSelectHand"
        :onSelectFinger="onSelectFinger"
        :onScan="onScan"
        :onClickEnd="onClickEnd"
        :onDiscard="onDiscard"
        :isNewPatient="isNewPatient"
        :disableEndButton="!areThereOneFingerOfEachHand" />
      <scanning-results
        v-else
        :status="status"
        :hand="selectedHand"
        :finger="selectedFinger"
        :saveDisabled="!hasResultImage"
        @scan="onTryAgain"
        @accept="onAccept"
        @replace="onReplace" />
    </template>
  </DefaultContainer>
  <portal to="modal-place">
    <ErrorModal
      v-if="showErrorModal"
      :title="$t('main.error')"
      :subtitle="$t('main.patient_not_registered')"
      image="patient-error"
      :buttons="errorModalButtons"
      @close="() => showErrorModal = false"/>
  </portal>
  <portal to="modal-place">
    <AlreadyExistsModal
      v-if="showAlreadyRegisteredFingerprintModal"
      :onClicked="closeAlreadyExistsModal"
      @close="() => showAlreadyRegisteredFingerprintModal = false"/>
  </portal>
  <portal to="modal-place">
    <SuccesModal
      v-if="showSuccessModal"
      :patientId="patientId"
      showButton="true"
      :message="$t('main.accept')"
      :onClicked="closeSuccessModal"/>
  </portal>
  <portal to="modal-place">
    <LowQualityModal
      v-if="isLowQuality && isNewPatient"
      :onClicked="captureFingerprint"
    />
  </portal>
  <portal to="modal-place">
    <Scanning
      v-if="loading"
      class="he-ui-scanning-modal"/>
  </portal>
  <portal to="modal-place">
    <LeaveRegister
      v-if="showLeavingModal"
      :buttons="leavingModalButtons"
      :isNewPatient="isNewPatient"/>
  </portal>
</template>

<script>
import { HHID, IS_NEW_PATIENT } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME, SCAN_MULTIFINGER_PAGE_ROUTE_NAME, STORED_FINGERPRINT_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AlreadyExistsModal from '@/components/AlreadyExistsModal'
import DefaultContainer from '@/components/DefaultContainer'
import DiscardFingerprint from '@/components/DiscardFingerprint'
import ErrorModal from '@/components/ErrorModal'
import Hand from '@/components/Hand'
import Header from '@/components/Header'
import LeaveRegister from '@/components/LeaveRegister'
import LowQualityModal from '@/components/LowQualityModal'
import MultiSelection from '@/components/MultiSelection'
import Preview from '@/components/Preview'
import Scanning from '@/components/Scanning'
import ScanningResults from '@/components/ScanningResults'
import SuccesModal from '@/components/SuccesModal'
import fingerprintReader from '@/composition/fingerprintReader'
import mixins from '@/composition/mixins'

export default {
  name: 'ScanMultipleFingersPage',
  components: {
    AlreadyExistsModal,
    DefaultContainer,
    DiscardFingerprint,
    ErrorModal,
    Hand,
    Header,
    LeaveRegister,
    LowQualityModal,
    MultiSelection,
    Preview,
    Scanning,
    ScanningResults,
    SuccesModal
  },
  beforeRouteLeave (to, from) {
    // Show exit confirm modal only if there are some fingerfrint saved
    if (!this.canLeavePage && this.savedFingers.length > 0) {
      this.showLeavingModal = true
      return false
    }
  },
  setup () {
    const { imageData, clean, captureFingerprint, hasResultImage, isLowQuality, resetFingerprint, stopCapture } = fingerprintReader()
    const { sendDataToParentClient } = mixins()

    return {
      imageData,
      clean,
      captureFingerprint,
      hasResultImage,
      isLowQuality,
      resetFingerprint,
      sendDataToParentClient,
      stopCapture
    }
  },
  data () {
    return {
      selectedHand: null,
      selectedFinger: null,
      scanning: false,
      showDiscardSection: false,
      showAlreadyRegisteredFingerprintModal: false,
      showErrorModal: false,
      showSuccessModal: false,
      showLeavingModal: false,
      isNewPatient: false,
      status: 'finished',
      savedFingers: [],
      loading: false,
      patientId: localStorage.getItem(HHID) ? localStorage.getItem(HHID) : null,
      canLeavePage: false,
      OK_MESSAGE: 'OK'
    }
  },
  computed: {
    ...mapGetters([
      'getAllSensors'
    ]),
    errorModalButtons () {
      return [
        { text: this.$t('main.try_again'), template: 'white', handler: () => { this.showErrorModal = false; this.captureFingerprint() } },
        { text: this.$t('main.new_patient'), template: 'blue', handler: this.onNewPatient }
      ]
    },
    leavingModalButtons () {
      return [
        { text: this.isNewPatient ? this.$t('main.continue_registration') : this.$t('main.cancel'), template: 'white', handler: () => { this.showLeavingModal = false } },
        { text: this.$t('main.exit'), template: 'blue', handler: () => { this.hideAndLeavePage() } }
      ]
    },
    headerText () {
      return this.isNewPatient ? this.$t('main.new_patient') : this.$t('main.identification')
    },
    emptyFingerprints () {
      return this.savedFingers.length === 0
    },
    areThereOneFingerOfEachHand () {
      return (this.savedFingers.filter((i) => i < 5).length > 0 && this.savedFingers.filter((d) => d >= 5).length > 0)
    }
  },
  mounted () {
    this.isNewPatient = localStorage.getItem(IS_NEW_PATIENT) ? localStorage.getItem(IS_NEW_PATIENT) : false
    this.retrieveFingerprintsForPatient()
  },
  methods: {
    ...mapActions([
      'createPatient',
      'fetchPatientFingerprints',
      'loginPatient',
      'registerFingerprint',
      'deletePatient'
    ]),
    ...mapMutations([
      'resetPatientId'
    ]),
    goBack () {
      return !this.scanning ? this.showConfirmMessage() : this.changeStatus()
    },
    showConfirmMessage () {
      if (this.isNewPatient && this.savedFingers.length > 0) {
        this.showLeavingModal = true
      } else {
        this.goToMainPage()
      }
    },
    hideAndLeavePage () {
      this.showLeavingModal = false
      this.canLeavePage = true
      if (this.isNewPatient) {
        // 2023-05-05 Dont delete patient temporarily, about an error (constraint violation) on backend (Ruben dixit)
        // this.deletePatient(this.patientId)
        //   .then(() => {
        this.goToMainPage()
        //  })
      } else {
        this.goToMainPage()
      }
    },
    goToMainPage () {
      this.$router.push({ name: MAIN_PAGE_ROUTE_NAME })
    },
    changeStatus () {
      this.scanning = false
    },
    onScan () {
      this.scanning = true
      this.captureFingerprint()
    },
    onSelectHand (value) {
      this.selectedHand = value
    },
    onSelectFinger (value) {
      this.selectedFinger = value
    },
    onReplace () {
      this.resetFingerprint()
      this.captureFingerprint()
    },
    onAccept () {
      if (this.patientId) {
        this.onRegisterFingerprint()
      } else {
        this.loading = true
        const data = {
          sensorId: this.getAllSensors[0].id,
          fingerprintBase64: this.clean(this.imageData),
          fingerNumber: this.selectedFinger
        }
        this.loginPatient(data)
          .then((response) => {
            this.patientId = response.hhid
            // Connect with hospital's software
            this.sendDataToParentClient(this.patientId, this.isNewPatient)
            this.showSuccessModal = true
          })
          .catch(() => {
            this.resetFingerprint()
            this.showErrorModal = true
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    closeSuccessModal () {
      this.canLeavePage = true
      this.showLeavingModal = false
      this.showSuccessModal = false
      this.resetPatientId()
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    },
    closeAlreadyExistsModal () {
      this.showAlreadyRegisteredFingerprintModal = false
    },
    onNewPatient () {
      this.showErrorModal = false
      this.createPatient()
        .then((response) => {
          this.scanning = false
          this.isNewPatient = true
          this.patientId = response.id
          this.stopCapture()
          localStorage.setItem(HHID, response.id)
          localStorage.setItem(IS_NEW_PATIENT, true)
          this.$router.push({
            name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
          })
        })
    },
    onTryAgain () {
      if (this.patientId) {
        this.onRegisterFingerprint()
      } else {
        this.loading = true
        const data = {
          sensorId: this.getAllSensors[0].id,
          fingerprintBase64: this.clean(this.imageData),
          fingerNumber: this.selectedFinger
        }
        this.loginPatient(data)
          .then((response) => {
            // Connect with hospital's software
            this.patientId = response.hhid
            this.showSuccessModal = true
            setTimeout(() => {
              this.closeSuccessModal()
              this.resetPatientId()
            }, 3000)
          })
          .catch(() => {
            this.resetFingerprint()
            this.showErrorModal = true
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onDiscard () {
      this.showDiscardSection = true
      this.isNewPatient = false
    },
    onRegisterFingerprint () {
      this.loading = true
      const data = {
        patientId: this.patientId,
        sensorId: this.getAllSensors[0].id,
        fingerprintBase64: this.clean(this.imageData),
        fingerNumber: this.selectedFinger
      }
      this.registerFingerprint(data)
        .then(() => {
          this.retrieveFingerprintsForPatient()
        })
        .catch((e) => {
          this.showAlreadyRegisteredFingerprintModal = true
          this.scanning = false
          this.resetFingerprint()
          this.stopCapture()
        })
        .finally(() => {
          this.loading = false
        })
    },
    retrieveFingerprintsForPatient () {
      if (!this.patientId) return
      this.fetchPatientFingerprints({ patientId: this.patientId })
        .then((response) => {
          const savedFingerData = response.filter(finger => finger.fingerNumber === this.selectedFinger)
          if (savedFingerData.length > 0 && savedFingerData[0].status.toUpperCase() !== this.OK_MESSAGE) {
            alert(this.$t('main.error_registering_fingerprint'))
          }
          this.savedFingers = response.filter(finger => finger.status === this.OK_MESSAGE).map(finger => finger.fingerNumber)
          this.status = 'finished'
          this.scanning = false
          this.resetFingerprint()
          this.captureFingerprint()
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClickEnd () {
      this.canLeavePage = true
      this.showLeavingModal = false

      this.$router.push({
        name: STORED_FINGERPRINT_PAGE_ROUTE_NAME
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
