<template>
  <modal v-on="$attrs">
    <template #body>
        <div class="he-ui-lowquality-modal-circle mb-4 d-flex align-items-center justify-content-center">
          <img
            :src="fingerprintRedImage"
            alt="image-low-quality" />
        </div>
        <p class="he-ui-lowquality-modal-message">
          {{ fingerprintLowQualityMessage }}
        </p>
        <Button
          template="blue"
          rounded
          uppercase
          class="mt-4"
          :text="buttonText"
          :onClick="onClicked"
        />
    </template>
  </modal>
</template>

<script>
import Button from '@/components/MainButton.vue'
import Modal from './Modal.vue'
import fingerprintRed from '@/assets/fingerprint-red.svg'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    onClicked: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      fingerprintRedImage: fingerprintRed,
      fingerprintLowQualityMessage: this.$t('main.fingerprint_low_quality_message'),
      buttonText: this.$t('main.try_again')
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-lowquality-modal-circle{
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background: rgba(255, 66, 54, 0.09);
   img {
    width: 150px;
   }
}
.he-ui-lowquality-modal-message {
  font-family: 'Poppins';
  font-weight: 500;
  line-height: 46px;
  font-size: 31px;
  color: #000000;
  padding: 15px 85px;
}
</style>
