<template>
 <div class="he-ui-scan-selection-wrapper d-flex flex-column justify-content-center align-items-start h-100">

  <div class="text-left he-ui-font-normal-bold mb-4">
    {{ $t('main.select_hand_and_finger_to_scan') }}:
  </div>

  <div class="he-ui-scan-fingers-hands-mini mb-4">
    <div
      v-for="(hand, index) in hands"
      :key="index"
      :class="{'hand-selected': selectedHand === index}"
      class="he-ui-scan-fingers-hand-img"
      @click="onClickSelectHand(index)">
      <img
        :src="hand.src"
        :alt="hand.alt" />
        <div class="mt-3">{{ hand.text }}</div>
    </div>
  </div>

  <div class="he-ui-scan-fingers-buttons">
    <div
      v-for="finger in fingers"
      :key="finger.index"
      class="he-ui-scan-fingers-button d-flex align-items-center justify-content-between"
      :class="{'selected': isFingerSelected(finger.index)}"
      @click="onClickSelectFinger(finger.index)" >
      <div>{{ finger.text }}</div>
      <div class="he-ui-scan-fingers-button-index">{{ getIndexFinger(finger.index) + 1 }}</div>
    </div>
  </div>

  <div class="w-100 mt-4 d-flex">
    <!-- 2023-05-05 Hide Discarbutto temporarily -->
    <Button
      v-if="isNewPatient"
      v-show="false"
      class="me-2 px-3"
      template="white"
      rounded
      uppercase
      :text="$t('main.discard')"
      :onClick="onClickDiscard" />
    <Button
      :class="{'w-100': !isNewPatient}"
      class="px-3"
      template="white"
      rounded
      uppercase
      :text="$t('main.scan')"
      :onClick="onClickScan" />
    <Button
      v-if="showEndbutton"
      class="ms-2 "
      template="blue"
      rounded
      uppercase
      :disabled="disableEndButton"
      icon="keyboard_arrow_right"
      :text="$t('main.end')"
      :onClick="onClickEnd" />
  </div>

</div>
</template>

<script>
import { LAST_INDEX_RIGHT_HAND } from '@/constants/constants'
import { mapGetters, mapMutations } from 'vuex'
import Button from '@/components/MainButton'

// const RIGHT_HAND = 1
const INDEXFINGER = 1

export default {
  components: {
    Button
  },
  props: {
    onScan: {
      type: Function,
      default: () => {}
    },
    onDiscard: {
      type: Function,
      default: () => {}
    },
    onSelectHand: {
      type: Function,
      default: () => {}
    },
    onSelectFinger: {
      type: Function,
      default: () => {}
    },
    isNewPatient: {
      type: Boolean,
      default: false
    },
    onClickEnd: {
      type: Function,
      default: () => {}
    },
    disableEndButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getHandSelected',
      'getFingerSelected'
    ]),
    showEndbutton () {
      console.log(this.disableEndButton)
      return this.isNewPatient && !this.disableEndButton
    }
  },
  data () {
    return {
      selectedHand: null,
      selectedFinger: null,
      hands: [
        { src: require('../assets/left-hand.png'), alt: 'left_hand', text: this.$t('main.left_hand') },
        { src: require('../assets/right-hand.png'), alt: 'right_hand', text: this.$t('main.right_hand') }
      ],
      fingers: [
        { text: this.$t('main.thumb'), index: 0 },
        { text: this.$t('main.indexfinger'), index: 1 },
        { text: this.$t('main.middlefinger'), index: 2 },
        { text: this.$t('main.ringfinger'), index: 3 },
        { text: this.$t('main.pinky'), index: 4 }
      ]
    }
  },
  mounted () {
    this.onClickSelectHand(this.getHandSelected)
    this.onClickSelectFinger(this.getFingerSelected)
  },
  methods: {
    ...mapMutations([
      'setHandSelected',
      'setFingerSelected'
    ]),
    onClickSelectHand (hand) {
      this.selectedHand = hand
      this.selectedFinger = this.selectedHand ? LAST_INDEX_RIGHT_HAND - INDEXFINGER : INDEXFINGER
      this.setHandSelected(this.selectedHand)
      if (this.onSelectHand) this.onSelectHand(this.selectedHand)
      if (this.onSelectFinger) this.onSelectFinger(this.selectedFinger)
    },
    onClickSelectFinger (finger) {
      this.selectedFinger = this.selectedHand ? LAST_INDEX_RIGHT_HAND - finger : finger
      this.setFingerSelected(finger)
      if (this.onSelectFinger) this.onSelectFinger(this.selectedFinger)
    },
    isFingerSelected (index) {
      return this.selectedHand ? this.selectedFinger === LAST_INDEX_RIGHT_HAND - index : this.selectedFinger === index
    },
    getIndexFinger (index) {
      return this.selectedHand ? LAST_INDEX_RIGHT_HAND - index : index
    },
    onClickDiscard () {
      // if (this.onDiscard) this.onDiscard()
    },
    onClickScan () {
      if (this.onScan) this.onScan()
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-scan-selection-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-right: 220px;
    padding-left: 50px;
    @media (max-width: 1180px) {
      padding-right: 185px;
    }
  }

  .he-ui-scan-fingers-hands-mini {
    display: flex;
    .he-ui-scan-fingers-hand-img {
      width: 217px;
      height: 217px;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 27px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $grey-dark-color;
      font-size: .8rem;
      font-weight: 700;
      padding: 30px;
      &:first-child {
        margin-right: 16px;
      }
      img {
        max-height: 100%;
      }
      &.hand-selected {
        background: $blue-color;
        color: white;
      }
    }
  }
  @media (max-height: 780px) {
    .he-ui-scan-fingers-hand-img {
      width: 150px !important;
      height: 150px !important;
    }
    .he-ui-scan-fingers-button {
      width: 150px !important;
    }
  }
  .he-ui-scan-fingers-buttons {
    display: flex;
    gap: 9px;
    flex-wrap: wrap;
  .he-ui-scan-fingers-button {
    // min-width: 220px;
    width: 217px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $grey-dark-color;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    background: white;
    font-size: .8rem;
    cursor: pointer;
    &.selected {
      background: $blue-color;
      color: white;

      .he-ui-scan-fingers-button-index {
        background: white;
        color: $blue-color;
      }
    }

    .he-ui-scan-fingers-button-index {
      background: $grey-dark-color;
      color: white;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      font-size: .7rem;
    }
  }
}
</style>
