<template>
  <div class="he-ui-discard-fingerprint-wrapper">

    <div class="text-left he-ui-font-normal-bold mb-4">
      {{ $t('main.discard_fingerprint_reason') }}:
    </div>

    <div class="he-ui-discard-fingerprint-form bg-white d-flex align-items-center flex-column p-4">
      <info-box
        class="he-ui-info-box mt-2 mb-4"
        status="initial"
        :hand="hand"
        :finger="finger"/>

      <div class="d-flex align-items-center flex-column">
        <div
          v-for="radio in radioButtons"
          :key="radio.id">
          <input
            type="radio"
            :id="radio.id"
            :value="radio.value"
            v-model="reason">
          <label :for="radio.id">{{ radio.label }}</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InfoBox from '@/components/InfoBox'

export default {
  components: {
    InfoBox
  },
  props: {
    hand: {
      type: Number,
      default: null
    },
    finger: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      reason: null,
      radioButtons: [
        { id: 'always', value: 'always', label: this.$t('main.not_available_forever') },
        { id: 'temporarily', value: 'temporarily', label: this.$t('main.not_available_temporarily') }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-info-box {
    width: 527px;
    height: 72px;
  }
.he-ui-discard-fingerprint-form {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

</style>
