<template>
  <modal v-on="$attrs">
    <template #body>
        <div class="my-5 d-flex align-items-center justify-content-center">
          <img
            :src="alreadyExistsImage"
            alt="image-low-quality" />
        </div>
        <div class="he-ui-already-exists-modal-title mb-2">
          {{ alreadyExistsTitle }}
        </div>
        <div class="he-ui-already-exists-modal-subtitle">
          {{ alreadyExistsSubtitle }}
        </div>
        <Button
          template="blue"
          rounded
          uppercase
          class="mt-5"
          :text="buttonText"
          :onClick="onClicked"
        />
    </template>
  </modal>
</template>

<script>
import Button from '@/components/MainButton.vue'
import Modal from './Modal.vue'
import alreadyExistsImg from '@/assets/fingerprint-exists.svg'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    onClicked: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      alreadyExistsImage: alreadyExistsImg,
      alreadyExistsTitle: this.$t('main.error_fingerprint_stored'),
      alreadyExistsSubtitle: this.$t('main.please_try_another'),
      buttonText: this.$t('main.accept')
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-already-exists-modal-title {
  font-family: 'Poppins';
  font-weight: 700;
  line-height: 46px;
  font-size: 31px;
  color: #000000;
  // padding: 15px 85px;
}
.he-ui-already-exists-modal-subtitle {
  font-family: 'Poppins';
  font-weight: 500;
  line-height: 46px;
  font-size: 31px;
  color: #AEAEAE;
  // padding: 15px 85px;
}
</style>
